.dialogContainer {
  margin: 10px;
  background: #ffffff;
  border: 1px solid #333333;
  width: 408px;
  height: 260px;
  box-shadow: 6px 6px #a693f3;
}
.dialogContainer .dialogContent .select {
  width: 100%;
  height: 40px;
  margin: 20px 0 16px;
  border: 1px solid #666666;
  border-radius: 2px;
}
.dialogContainer .dialogActions {
  background: #ffffff;
  padding: 20px 24px 0;
}
