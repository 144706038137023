.container .titleBox {
  display: flex;
  justify-content: center;
}
.container .titleBox .title {
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  /* identical to box height */
  display: flex;
  align-items: center;
  /* Eth Colors Gray /800 */
  color: #1a202c;
}
.container .postDetailCardContainer {
  padding: 0 10rem;
  height: 100%;
}
.container .postDetailCardContainer .postDetailCardBoxTitle {
  margin-top: 1vw;
  margin-bottom: 16px;
  font-family: "Satoshi-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  /* Eth Colors Gray /800 */
  color: #1a202c;
}
.container .postDetailCardContainer .postDetailCardBoxExtraInfo {
  margin: 0 0 13px;
}
.container .postDetailCardContainer .postCardClass {
  height: 30vw;
  overflow: auto;
}
.container .buttonList {
  padding: 68px 10rem;
}
.container .buttonList .publishButton {
  width: 119px;
  height: 40px;
}
