.container {
  position: relative;
}
.container .icon {
  cursor: pointer;
}
.container .notificationDotIcon {
  width: 45px;
  height: 45px;
  background: url("@/assets/icon/notificationDot.svg") no-repeat center;
  cursor: pointer;
}
.container .notificationDotIcon:hover {
  background: url("@/assets/icon/notificationDotHover.svg") no-repeat center;
}
.container .notificationDotIcon:active {
  background: url("@/assets/icon/notificationDotActive.svg") no-repeat center;
}
.container .notificationIcon {
  width: 45px;
  height: 45px;
  background: url("@/assets/icon/notification.svg") no-repeat center;
  cursor: pointer;
}
.container .notificationIcon:hover {
  background: url("@/assets/icon/notificationHover.svg") no-repeat center;
}
.container .notificationIcon:active {
  background: url("@/assets/icon/notificationActive.svg") no-repeat center;
}
.container .modalContainer {
  position: absolute;
  top: 45px;
  right: 0;
  width: 400px;
  height: 298px;
  box-shadow: 4px 4px #a693f3;
  background: white;
  border: 1px solid #333333;
  padding: 20px 16px 0 16px;
  overflow-y: auto;
}
.container .modalContainer .titleBox {
  margin-bottom: 20px;
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #333333;
}
.container .modalContainer .bell {
  margin-right: 10px;
}
.container .modalContainer .item {
  padding: 15px 0;
  border-top: solid 1px #f3f3f3;
}
.container .modalContainer .item .createdAt {
  margin-bottom: 4px;
  font-family: "Satoshi-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #737b7d;
}
.container .modalContainer .item .textBox .type,
.container .modalContainer .item .textBox .status {
  display: inline !important;
  font-family: "Satoshi-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  display: flex;
  align-items: center;
  color: #333333;
}
.container .modalContainer .item .textBox .title {
  display: inline !important;
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  display: flex;
  align-items: center;
  color: #333333;
}
