.container {
  background: #eff5f9;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .logo {
  width: 120px;
}
@media (max-width: 600px) {
  .container .logo {
    padding: 20vw;
  }
}
