body {
  margin: 0;
  font-family: "Satoshi-Bold";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

a {
  text-decoration: unset !important;
}

@font-face {
  font-family: Satoshi-Light;
  font-style: normal;
  src: url("assets/font/Satoshi-Light.ttf");
}

@font-face {
  font-family: Satoshi-Regular;
  font-style: normal;
  src: url("assets/font/Satoshi-Regular.ttf");
}

@font-face {
  font-family: Satoshi-Medium;
  font-style: normal;
  src: url("assets/font/Satoshi-Medium.ttf");
}

@font-face {
  font-family: Satoshi-Bold;
  font-style: normal;
  src: url("assets/font/Satoshi-Bold.ttf");
}

@font-face {
  font-family: Satoshi-Black;
  font-style: normal;
  src: url("assets/font/Satoshi-Black.ttf");
}

.hideScrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

select {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 40px;
  line-height: 12px;
  appearance: unset;
  background: url("@/assets/icon/arrowGray.svg") no-repeat 96%;
  color: #333;
  font-family: "Satoshi-Medium";
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
}

input {
  box-sizing: border-box;
  padding: 12px;

  width: 100%;
  height: 40px;
  color: #333;
}

.cancelButton {
  box-sizing: border-box;
  width: 83px;
  height: 40px;

  border: 1px solid #333333;
  border-radius: 4px;
  font-family: "Satoshi-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  justify-content: center;

  color: #333333;
  cursor: pointer;
}

.cancelButton:hover {
  background: #faf5ff;
}

.cancelButton:active {
  background: #e9d8fd;
}

.confirmButton {
  box-sizing: border-box;
  gap: 10px;

  width: 82px;
  height: 40px;

  background: #3d266f;
  border-radius: 4px;

  font-family: "Satoshi-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  justify-content: center;

  /* Light / White */

  color: #ffffff;
  cursor: pointer;
}

.confirmButton:hover {
  background: #553c9a;
}

.confirmButton:active {
  background: #322659;
}

.deleteButton {
  box-sizing: border-box;
  gap: 10px;

  width: 95px;
  height: 40px;

  background: #b80000;
  border-radius: 4px;

  font-family: "Satoshi-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  justify-content: center;

  /* Light / White */

  color: #ffffff;
  cursor: pointer;
}

.deleteButton:hover {
  background: #f56565;
}

.deleteButton:active {
  background: #c53030;
}
