.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  white-space: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0 48px 60px;
}
@media (max-width: 600px) {
  .footer {
    padding: 0 0 10px;
    margin-top: -290px;
  }
}
.footer .copyright {
  font-family: "Satoshi-Medium";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  display: flex;
  align-items: center;
  /* Netural 900 */
  color: #1a202c;
}
@media (max-width: 600px) {
  .footer .copyright {
    width: 50%;
    transform: scale(0.3);
    transform-origin: 15%;
  }
}
.footer .linkGrid {
  display: flex;
  align-items: center;
}
@media (max-width: 600px) {
  .footer .linkGrid {
    width: 50%;
    transform: scale(0.3);
    transform-origin: 35%;
  }
}
.footer .linkGrid .link {
  margin: 0 20px;
  font-family: "Satoshi-Medium";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  display: flex;
  align-items: center;
  text-align: center;
  /* Netural 900 */
  color: #1a202c;
}
.footer .linkGrid .link > img {
  margin-right: 10px;
}
