.container {
  position: absolute;
  right: 30px;
  top: 10px;
  z-index: 1;
  justify-content: center;
  width: 177px;
  height: 44px;
  box-sizing: border-box;
  background: #ffffff;
  /* Netural 900 */
  box-shadow: 2px 2px #b794f4;
  border: 1px solid #323a45;
  font-family: "Satoshi-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 150% */
  display: flex;
  align-items: center;
  /* Netural 900 */
  color: #44337a;
  cursor: pointer;
}
.container .address {
  margin: 0 8px;
}
.connectWallet:hover {
  background: linear-gradient(98.64deg, #a693f3 7.6%, #c9adfd 98.81%);
  /* Eth Colors Purple /800 */
  border: 1px solid #44337a;
  color: #ffffff;
}
.connectWallet:active {
  background: #805ad5;
  /* Eth Colors Purple /800 */
  border: 1px solid #44337a;
  color: #ffffff;
}
.menuContainer {
  position: absolute;
  right: 30px;
  top: 10px;
  flex-direction: column;
  align-items: start;
  height: fit-content;
  box-sizing: border-box;
}
.menuContainer .baseButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 6px;
  position: relative;
  top: -3px;
  left: 0px;
  padding: 10px;
}
.menuContainer .option {
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 18px;
  white-space: nowrap;
  font-family: "Satoshi-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  display: flex;
  align-items: center;
  color: #333333;
}
.menuContainer .option img {
  margin-right: 11px;
}
.menuContainer .option:hover {
  background: rgba(0, 0, 0, 0.04);
}
