.container {
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  bottom: 0;
  height: calc(100% - 64px);
}
.container .formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container .formContainer .title {
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  /* identical to box height */
  display: flex;
  align-items: center;
  /* Eth Colors Gray /800 */
  color: #1a202c;
}
.container .formContainer form {
  box-sizing: border-box;
  width: 100%;
  padding: 0 8rem;
}
.container .formContainer form .formContent .label {
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin: 44px 0 12px;
  color: #000000;
}
.container .formContainer form .formContent .label .notRequired {
  margin-left: 20px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #737b7d;
}
.container .formContainer form .formContent .uploadTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 44px 0 12px 10px;
}
.container .formContainer form .formContent .uploadTitle .label {
  margin: 0;
}
.container .formContainer form .formContent .checkbox {
  font-family: "Satoshi-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  display: flex;
  align-items: center;
  color: #737b7d;
  margin: 0;
}
.container .formContainer form .formContent .uploadBox {
  position: absolute;
}
.container .formContainer form .formContent .uploadBox .uploadTip1 {
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #333333;
  margin-top: 16px;
}
.container .formContainer form .formContent .uploadBox .uploadTip2 {
  font-family: "Satoshi-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  display: flex;
  align-items: center;
  color: #666666;
}
.container .formContainer form .formContent .uploadBox .uploadDirectory {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
