.eth {
  position: absolute;
  right: 24px;
  top: 24px;
}
.arrowRight {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: url("@/assets/icon/arrowRight.svg") no-repeat center;
}
.arrowRight:hover {
  background: url("@/assets/icon/arrowRightHover.svg") no-repeat center;
}
.arrowLeft {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: url("@/assets/icon/arrowLeft.svg") no-repeat center;
}
.arrowLeft:hover {
  background: url("@/assets/icon/arrowLeftHover.svg") no-repeat center;
}
.imageBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.imageBox .cid {
  text-align: center;
  visibility: hidden;
  word-break: break-all;
}
.imageBox .cid .fileName {
  margin: 20px 0 10px;
}
.autoPlaySwipeableViews {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
}
.mobileStepper {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent !important;
}
.downloadBox {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.downloadBox .download {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #f3f0f8;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}
.downloadBox .download > img {
  transform: rotate(135deg);
}
.downloadBox .cid {
  text-align: center;
  word-break: break-all;
}
.downloadBox .cid .fileName {
  margin: 20px 0 10px;
}
