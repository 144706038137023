.create {
  box-sizing: border-box;
  padding: 20px 24px 20px 19px;
  gap: 10px;
  height: 44px;
  background: #3d266f;
  border-radius: 4px;
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  /* Light / White */
  color: #ffffff;
  cursor: pointer;
}
.create:hover {
  background: #3d266fee;
}
.create .text {
  position: relative;
  z-index: 0;
}
.create .text .decoration {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 1.5px;
  background: #fff;
  z-index: -1;
}
