.postCard {
  position: relative;
  display: block;
  cursor: pointer;
  height: 497px;
  background: #ffffff;
  /* Eth Colors Gray /900 */
  border: 2px solid #141414;
  box-shadow: 4px 4px #7dd3fc;
}
.postCard .eth {
  position: absolute;
  right: 8px;
  top: 8px;
}
.postCard .moreBox {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("@/assets/icon/more.svg") no-repeat center;
}
.postCard .moreBox:hover {
  background: url("@/assets/icon/moreHover.svg") no-repeat center;
}
.postCard .moreBox:active {
  background: url("@/assets/icon/moreActive.svg") no-repeat center;
}
.postCard .moreBox .menuContainer {
  position: absolute;
  left: 18px;
  top: 20px;
  z-index: 1;
  background: white;
  flex-direction: column;
  align-items: start;
  height: fit-content;
  box-sizing: border-box;
  box-shadow: 2px 2px #b794f4;
  border: 1px solid #323a45;
}
.postCard .moreBox .menuContainer .baseButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 6px;
  position: relative;
  top: -3px;
  left: 0px;
}
.postCard .moreBox .menuContainer .option {
  justify-content: center;
  padding: 10px 20px;
  white-space: nowrap;
  font-family: "Satoshi-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #333333;
}
.postCard .moreBox .menuContainer .option:hover {
  background: rgba(0, 0, 0, 0.04);
}
.postCard .moreBox .menuContainer .option img {
  margin-right: 11px;
}
.postCard .title {
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  /* Eth Colors Gray /800 */
  color: #1a202c;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.postCard .description {
  font-family: "Satoshi-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  height: 44px;
  word-break: break-all;
  /* Eth Colors Gray /600 */
  color: #4a5568;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.postCard .extraInfo {
  display: flex;
  border-top: 1px solid #141414;
  height: 43px;
}
.postCard .extraInfo .item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
