.container {
  position: absolute;
  top: 7vw;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 10vw;
  background: #fff;
  box-sizing: border-box;
  background-color: white;
  background-image: url("@/assets/image/daoBright.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  width: 100%;
}
@media (max-width: 1000px) {
  .container {
    top: 10vw;
  }
}
.container .title {
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  line-height: 150%;
  /* or 48px */
  display: flex;
  align-items: center;
  /* Netural 900 */
  color: #323a45;
}
.container .daoWrapper {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5vw;
}
@media (max-width: 600px) {
  .container .daoWrapper {
    padding: 2vw 1vw;
  }
}
.container .daoWrapper .cardBoxWrapper {
  position: relative;
  width: 80%;
}
.container .daoWrapper .cardBoxWrapper .cardBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 60%;
}
.container .daoWrapper .cardBoxWrapper .cardBox .card {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  border: 0.1vw solid #323a45;
  box-shadow: 0.3vw 0.3vw #000;
}
.container .daoWrapper .cardBoxWrapper .cardBox .card .image {
  width: 2vw;
}
.container .daoWrapper .cardBoxWrapper .cardBox .card .text {
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 1.2vw;
  /* identical to box height, or 48px */
  display: flex;
  align-items: center;
  margin-top: 0.5vw;
  color: rgba(0, 0, 0, 0.92);
}
@media (max-width: 800px) {
  .container .daoWrapper .cardBoxWrapper .cardBox .card .text {
    white-space: nowrap;
    transform: scale(0.5);
  }
}
.container .absoluteFooter {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 3vw 4vw;
}
