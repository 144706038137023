.rightBright {
  padding-top: 20px;
  background-color: white;
  background-image: url("@/assets/image/rightBright.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  width: 100%;
  height: 100%;
}
.leftBright {
  background-image: url("@/assets/image/leftBright.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 200px;
  height: 100%;
}
.search {
  border: 1px solid #141414;
}
@media (max-width: 600px) {
  .search {
    border: 1px solid #8f8d8d;
    border-radius: 1px;
  }
}
.search:focus-within {
  border: 1px solid #b794f4;
  filter: drop-shadow(0px 0px 5px #c9acfd);
}
@media (max-width: 600px) {
  .search {
    height: 15px !important;
    width: 68% !important;
    margin: auto !important;
    padding: 0 5px !important;
  }
  .search img {
    transform: scale(0.5);
    transform-origin: left;
  }
  .search input {
    transform: scale(0.5);
    transform-origin: -10%;
  }
}
.body {
  padding: 0 216px;
}
@media (max-width: 600px) {
  .body {
    padding: 0;
  }
}
.body .title {
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
}
@media (max-width: 600px) {
  .body .title {
    font-size: 20px;
    line-height: 27px;
    transform: scale(0.42);
    transform-origin: 28%;
    white-space: nowrap;
  }
}
.body .title .titleBR {
  display: none;
}
.body .title .create {
  display: inline;
  font-family: "Satoshi-Black";
  cursor: pointer;
  position: relative;
  z-index: 0;
}
.body .title .create .decoration {
  position: absolute;
  left: 0;
  bottom: 6px;
  width: 100%;
  height: 8px;
  background: #a693f3;
  z-index: -1;
}
@media (max-width: 600px) {
  .body .title .create .decoration {
    height: 4px;
    bottom: 3px;
  }
}
.body .subtitle {
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  /* Netural 900 */
  color: #323a45;
}
@media (max-width: 600px) {
  .body .subtitle {
    transform: scale(0.5);
    transform-origin: 32% 100%;
  }
}
@media (max-width: 600px) {
  .cardGrid {
    transform: scale(0.7);
    transform-origin: top;
  }
}
.cardGrid .verticleGrid {
  width: 33.3%;
}
@media (max-width: 600px) {
  .cardGrid .verticleGrid {
    transform: scale(0.93);
    transform-origin: top;
  }
}
.cardGrid .verticleGrid .singleCard {
  width: unset;
  margin: 20px 0;
  border: 1.5px solid #323a45;
  background: white;
}
@media (max-width: 600px) {
  .cardGrid .verticleGrid .singleCard {
    padding: 10px 7px;
    margin: 10px 0;
    border: 1px solid #8f8d8d;
  }
}
@media (max-width: 600px) {
  .cardGrid .verticleGrid .singleCard .iconBox {
    height: 40px;
  }
}
@media (max-width: 600px) and (max-width: 600px) {
  .cardGrid .verticleGrid .singleCard .iconBox .icon {
    transform: scale(0.5);
    transform-origin: top left;
  }
}
.cardGrid .verticleGrid .singleCard .label {
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
  /* identical to box height, or 48px */
  display: flex;
  align-items: center;
  /* Netural 900 */
  color: #323a45;
  cursor: pointer;
}
@media (max-width: 600px) {
  .cardGrid .verticleGrid .singleCard .label {
    transform: scale(0.4);
    transform-origin: top left;
    height: 0;
    white-space: nowrap;
  }
}
@media (max-width: 600px) {
  .cardGrid .verticleGrid .singleCard .subLableBox {
    transform: scale(0.4);
    transform-origin: top left;
    white-space: nowrap;
  }
}
.cardGrid .verticleGrid .singleCard .subLableBox .subLable {
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: fit-content;
  cursor: pointer;
  text-align: left;
  /* Netural 900 */
  color: #323a45;
}
.cardGrid .verticleGrid .singleCard .subLableBox .subLable:hover {
  text-decoration: underline solid 2px #323a45;
  text-underline-offset: 5px;
}
.cardGrid .verticleGrid .singleCard .moreListings {
  position: relative;
  margin-top: 64px;
  font-family: "Satoshi-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  display: flex;
  align-items: center;
  /* Eth Colors Gray /900 */
  color: #141414;
  cursor: pointer;
  z-index: 0;
}
@media (max-width: 600px) {
  .cardGrid .verticleGrid .singleCard .moreListings {
    transform: scale(0.5);
    transform-origin: top left;
    white-space: nowrap;
  }
}
.cardGrid .verticleGrid .singleCard .moreListings .decoration {
  position: absolute;
  bottom: 3px;
  width: 100%;
  height: 4px;
  z-index: -1;
}
.footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  margin: 80px 0 128px;
}
@media (max-width: 600px) {
  .footer {
    margin: 20px 0 0;
    transform: scale(0.3);
    transform-origin: center -320px;
  }
}
.footer .postAList {
  font-family: "Satoshi-Black";
  color: #323a45;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 150%;
  /* identical to box height, or 48px */
  display: flex;
  align-items: center;
}
.footer .postAList .create {
  position: relative;
  cursor: pointer;
  z-index: 0;
}
.footer .postAList .create .decoration {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  height: 4px;
  background: #b794f4;
  z-index: -1;
}
@media (max-width: 600px) {
  .footer .postAList .create .decoration {
    bottom: 3px;
  }
}
@media (max-width: 600px) {
  .footer .postAList {
    font-size: 20px;
    line-height: 27px;
  }
}
.footer .walletConnectBox {
  position: relative;
  height: 120px;
  top: 10px;
  left: 120px;
}
.footer .tip {
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 150%;
  /* identical to box height, or 42px */
  /* Netural 900 */
  color: #323a45;
}
@media (max-width: 600px) {
  .footer .tip {
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    white-space: nowrap;
    margin-top: -20px;
  }
}
.footer .tip .link {
  position: relative;
  font-family: "Satoshi-Black";
  color: #323a45;
  margin: 0 5px;
  cursor: pointer;
  z-index: 0;
}
.footer .tip .link .decoration {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 97%;
  height: 4px;
  background: #b794f4;
  z-index: -1;
}
@media (max-width: 600px) {
  .footer .tip .link .decoration {
    bottom: 3px;
  }
}
.footer .tip .link:active .decoration {
  position: absolute;
  bottom: 9px;
  width: 100%;
  height: 4px;
  background: #9f7aea;
  z-index: -1;
}
.footer .blockHeight {
  font-family: "Satoshi-Bold";
  font-style: normal;
  margin-top: 40px;
}
.tabAndListwrapper {
  position: absolute;
  top: 64px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fefdff;
  padding-top: 64px;
}
@media (max-width: 600px) {
  .tabAndListwrapper {
    padding-top: 0;
  }
}
.tabAndListwrapper .homeTabsWrapper {
  padding: 0 192px;
}
.tabAndListwrapper .homeTabsWrapper .globalSearch {
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  /* identical to box height */
  display: flex;
  align-items: center;
  /* Eth Colors Gray /800 */
  color: #1a202c;
}
.tabAndListwrapper .homeTabsWrapper .firstLevel {
  font-family: "Satoshi-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 54px;
  /* identical to box height */
  display: flex;
  align-items: center;
  /* Eth Colors Gray /800 */
  color: #1a202c;
}
.tabAndListwrapper .homeTabsWrapper .secondLevel {
  cursor: pointer;
}
.tabAndListwrapper .homeTabsWrapper .secondLevel,
.tabAndListwrapper .homeTabsWrapper .thirdLevel {
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  /* identical to box height */
  display: flex;
  align-items: center;
  /* Eth Colors Gray /800 */
  color: #1a202c;
}
.tabAndListwrapper .homeTabsWrapper .quantity {
  font-family: "Satoshi-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  display: flex;
  align-items: center;
  color: #4a5568;
}
.tabAndListwrapper .postsListWapper {
  position: absolute;
  top: 280px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  padding: 0 192px;
}
.tabAndListwrapper .postsListWapper .absoluteFooter {
  position: absolute;
  left: 0;
  right: 0;
}
