.appBar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px 0 45px !important;
}
@media (max-width: 600px) {
  .appBar .container {
    padding: 0 10px !important;
    height: 0;
    min-height: 10px;
  }
}
.appBar .container .logoBox {
  display: flex;
  align-items: center;
}
@media (max-width: 600px) {
  .appBar .container .logoBox {
    width: 50%;
    transform: scale(0.5);
    transform-origin: left 100%;
  }
}
@media (max-width: 600px) {
  .appBar .container .logoBox .logo {
    width: auto;
    height: 35px;
  }
}
.appBar .container .right {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 600px) {
  .appBar .container .right {
    width: 50%;
    transform: scale(0.3);
    transform-origin: 105% 70%;
  }
}
.appBar .container .right .navBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 195px;
}
@media (max-width: 600px) {
  .appBar .container .right .navBox {
    margin-right: 550px;
    white-space: nowrap;
    margin-top: 20px;
  }
}
.appBar .container .right .navBox .nav {
  position: relative;
  font-family: "Satoshi-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  display: flex;
  align-items: center;
  color: #000000;
  margin: 0 20px;
}
.appBar .container .right .navBox .nav:hover .decoration {
  position: absolute;
  bottom: 4px;
  width: 100%;
  height: 4px;
  background: #b794f4;
  z-index: -1;
}
.appBar .container .right .navBox .nav:active .decoration {
  position: absolute;
  bottom: 4px;
  width: 100%;
  height: 4px;
  background: #9f7aea;
  z-index: -1;
}
.appBar .container .menu {
  display: none;
}
