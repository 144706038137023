.rightContainer {
  position: absolute;
  top: 120px;
  bottom: 0;
  left: 216px;
  right: 0;
  padding: 0 48px;
  background: #fff;
  box-sizing: border-box;
}
.rightContainer .title {
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  /* Eth Colors Gray /800 */
  color: #1a202c;
}
.rightContainer .create {
  box-sizing: border-box;
  margin-top: 5px;
  padding: 20px 24px;
  gap: 10px;
  height: 44px;
  background: #3d266f;
  border-radius: 4px;
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  /* Light / White */
  color: #ffffff;
  cursor: pointer;
}
.rightContainer .create:hover {
  background: #3d266fee;
}
.rightContainer .active {
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 50px;
  display: flex;
  align-items: center;
  /* Eth Colors Gray /800 */
  color: #1a202c;
  margin: 50px 0 40px;
  text-decoration: underline solid 2px #1a202c;
  text-underline-offset: 17px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.rightContainer .quantity {
  font-family: "Satoshi-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  display: flex;
  align-items: center;
  color: #4a5568;
  margin-bottom: 16px;
}
