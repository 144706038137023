@media (min-width: 600px) {
  .container {
    height: 100%;
  }
}
.container .title {
  width: calc(100% - 250px);
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  /* identical to box height */
  display: flex;
  align-items: center;
  /* Eth Colors Gray /800 */
  color: #1a202c;
}
@media (max-width: 600px) {
  .container .title {
    font-size: 20px;
    line-height: 27px;
  }
}
@media (min-width: 600px) {
  .container .toolbar {
    transform: translateY(30px);
  }
}
.container .toolbar .share {
  width: 45px;
  height: 45px;
  margin-right: 20px;
  border-radius: 50%;
  cursor: pointer;
  background: url("@/assets/icon/share.svg") no-repeat center;
}
.container .toolbar .share:hover {
  background: url("@/assets/icon/shareHover.svg") no-repeat center;
}
.container .toolbar .share:active {
  background: url("@/assets/icon/shareActive.svg") no-repeat center;
}
.container .extraInfo {
  display: flex;
  margin: 30px 0;
}
@media (max-width: 600px) {
  .container .extraInfo {
    align-items: flex-start;
    flex-direction: column;
    margin: 20px 0 10px;
  }
}
.container .extraInfo .item {
  justify-content: center;
  margin-right: 62px;
  font-family: "Satoshi-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  display: flex;
  align-items: center;
  /* Eth Colors Gray /800 */
  color: #1a202c;
}
@media (max-width: 600px) {
  .container .extraInfo .item {
    margin-bottom: 10px;
  }
}
.container .extraInfo .item img {
  margin-right: 10px;
}
.container .postCard {
  height: 30vw;
  overflow: auto;
  display: flex;
  padding: 16px;
  background: #ffffff;
  /* Eth Colors Gray /800 */
  border: 2px solid #1a202c;
  box-shadow: 6px 6px #b794f4;
}
@media (max-width: 600px) {
  .container .postCard {
    height: auto;
  }
}
@media (max-width: 600px) {
  .container .postCard {
    flex-direction: column;
    align-items: center;
  }
}
.container .postCard .eth {
  position: absolute;
  right: 24px;
  top: 24px;
}
.container .postCard .priceDescriptionBox {
  overflow: auto;
}
.container .postCard .priceDescriptionBox .price {
  margin-bottom: 40px;
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 65px;
  /* identical to box height */
  display: flex;
  align-items: center;
  /* Eth Colors Gray /800 */
  color: #1a202c;
}
@media (max-width: 600px) {
  .container .postCard .priceDescriptionBox .price {
    margin-top: 20px;
    font-size: 20px;
    line-height: 27px;
  }
}
.container .postCard .priceDescriptionBox .title {
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  /* Eth Colors Gray /700 */
  color: #222222;
}
.container .postCard .priceDescriptionBox .description {
  font-family: "Satoshi-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
  /* Eth Colors Gray /600 */
  color: #4a5568;
}
.container .postCard .contactBox {
  display: flex;
}
.container .postCard .contactBox .contacts {
  margin-left: 17px;
  padding: 7px 0;
  font-family: "Satoshi-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  /* Eth Colors Gray /800 */
  color: #1a202c;
}
