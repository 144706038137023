.container {
  position: absolute;
  top: 64px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px 48px 0;
  background: #fefdff;
}
@media (max-width: 600px) {
  .container {
    padding: 10px 20px 0;
  }
}
.container .title {
  margin-bottom: 16px;
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  /* identical to box height */
  display: flex;
  align-items: center;
  /* Eth Colors Gray /800 */
  color: #1a202c;
}
.container .extraInfo {
  display: flex;
  margin: 20px 0;
}
.container .extraInfo .item {
  justify-content: center;
  margin-right: 62px;
  font-family: "Satoshi-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  display: flex;
  align-items: center;
  /* Eth Colors Gray /800 */
  color: #1a202c;
}
.container .extraInfo .item img {
  margin-right: 10px;
}
.container .postCard {
  display: flex;
  padding: 16px;
  background: #ffffff;
  /* Eth Colors Gray /800 */
  border: 2px solid #1a202c;
  box-shadow: 6px 6px #b794f4;
}
.container .postCard .eth {
  position: absolute;
  right: 24px;
  top: 24px;
}
.container .postCard .price {
  margin-bottom: 40px;
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 65px;
  /* identical to box height */
  display: flex;
  align-items: center;
  /* Eth Colors Gray /800 */
  color: #1a202c;
}
.container .postCard .title {
  font-family: "Satoshi-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  /* Eth Colors Gray /700 */
  color: #222222;
}
.container .postCard .description {
  font-family: "Satoshi-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
  /* Eth Colors Gray /600 */
  color: #4a5568;
}
.container .postCard .contactBox {
  display: flex;
}
.container .postCard .contactBox .contacts {
  margin-left: 17px;
  padding: 7px 0;
  font-family: "Satoshi-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  /* Eth Colors Gray /800 */
  color: #1a202c;
}
.container .footer {
  margin-top: 3vw;
}
@media (max-width: 600px) {
  .container .footer {
    margin-top: 20vw;
  }
}
@media (min-height: 1000px) {
  .container .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
@media (max-height: 1000px) {
  .container .footer {
    padding: 0 0 60px;
  }
}
