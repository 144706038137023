.tip,
.add {
  font-family: "Satoshi-Meduim";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #666666;
  padding: 0 12px;
}
.add {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}
