.react-swipeable-view-container {
  width: 100%;
  height: 100%;
}
.react-swipeable-view-container > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden !important;
  height: 100%;
}
.MuiMobileStepper-dots {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
}
.MuiMobileStepper-dot {
  width: 16px !important;
  height: 3px !important;
  /* Neutral/1 */
  background: #000 !important;
  opacity: 0.3 !important;
  border-radius: 1px !important;
}
.MuiMobileStepper-dotActive {
  width: 24px !important;
  height: 3px !important;
  opacity: 1 !important;
  /* Neutral/1 */
  background: #000 !important;
  border-radius: 1px !important;
}
.MuiMobileStepper-root {
  position: relative;
}
.MuiMobileStepper-root
  .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall {
  padding: 0 !important;
  background: none !important;
  color: unset !important;
}
.MuiMobileStepper-root .Mui-disabled {
  visibility: hidden !important;
}
