.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px 0 45px !important;
}
@media (max-width: 600px) {
  .container {
    background: #ffffff;
    border-bottom: 1px solid #141414;
    padding: 0 22px !important;
  }
}
.container .logoBox {
  display: flex;
  align-items: center;
}
@media (max-width: 600px) {
  .container .logoBox .logo {
    width: auto;
    height: 35px;
  }
}
.container .right {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 600px) {
  .container .right {
    display: none;
  }
}
.container .right .navBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 195px;
}
.container .right .navBox .nav {
  position: relative;
  font-family: "Satoshi-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  display: flex;
  align-items: center;
  color: #000000;
  margin: 0 20px;
  white-space: nowrap;
}
.container .right .navBox .nav:hover .decoration {
  position: absolute;
  bottom: 4px;
  width: 100%;
  height: 4px;
  background: #b794f4;
  z-index: -1;
}
.container .right .navBox .nav:active .decoration {
  position: absolute;
  bottom: 4px;
  width: 100%;
  height: 4px;
  background: #9f7aea;
  z-index: -1;
}
.container .menu {
  display: none;
}
@media (max-width: 600px) {
  .container .menu {
    display: inline;
  }
}
