.dialogContainer {
  margin: 10px;
  background: #ffffff;
  border: 1px solid #333333;
  width: 400px;
  height: 200px;
  box-shadow: 6px 6px #a693f3;
}
.dialogContainer .dialogContent .tip {
  font-family: "Satoshi-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  margin-top: 16px;
  color: #666666;
}
.dialogContainer .dialogActions {
  background: #ffffff;
  padding: 20px 24px 0;
}
